import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { FilterService } from '@progress/kendo-angular-grid';
import { Logger } from '@app/core';
import { Type } from '@app/settings/types/types.model';
import { ApiQuery, ApiQuerySortTypes } from '@app/core/http/api-query';
import { PaginatedResponse } from '@app/shared/models/paginated-response.model';
import { TypesService } from '@app/settings/types/types.service';
import { CustomKendoFilter } from '@app/shared/kendo/custom-kendo-filter';
import { CompositeFilterDescriptor } from '@progress/kendo-data-query';
import { FilterDescriptor } from '@progress/kendo-data-query/dist/npm/filtering/filter-descriptor.interface';
import { Subject } from 'rxjs/internal/Subject';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

const log = new Logger('KendoTypesSelectComponent');

@Component({
  selector: 'app-kendo-types-select',
  templateUrl: './kendo-types-select.component.html',
  styleUrls: ['./kendo-types-select.component.scss'],
})
export class KendoTypesSelectComponent extends CustomKendoFilter implements OnInit, OnChanges {
  @Input() field = 'type_id';
  @Input() groupType: string = null;
  data: Array<Type>;
  apiQuery: ApiQuery = new ApiQuery();
  loader: boolean;

  @Output() change: EventEmitter<CompositeFilterDescriptor> = new EventEmitter<CompositeFilterDescriptor>();
  @Input() placeholder: string;

  filterChanged: Subject<CompositeFilterDescriptor> = new Subject<CompositeFilterDescriptor>();

  constructor(private formBuilder: FormBuilder, filterService: FilterService, private typeService: TypesService) {
    super(filterService);

    this.filterChanged.pipe(debounceTime(1500),distinctUntilChanged())
      .subscribe(model => {
        console.log(model);
        this.filterService.filter(model);
        this.change.emit(model);
    });
  }

  ngOnInit() {
    this.form = this.formBuilder.group({
      value: [this.getObjectIdMultiple(this.field)],
    });
    this.loadTypes();
  }

  ngOnChanges(changes: SimpleChanges) {
    // Изчиства формата
    // Използва се, за да чисти филтрите, когато се сетне null state на таблицата
    if (this.form && changes && changes.filter && !changes.filter.currentValue) {
      this.form.reset();
    }
  }

  onSelect(event: any) {
    // this.filter = this.removeFilter(this.field);

    const filters: Array<FilterDescriptor | CompositeFilterDescriptor> = [];

    if (event.length > 0) {
      event.forEach((type: Type) => {
        filters.push({
          field: this.field,
          operator: 'eq',
          value: type.id,
        });
      });
    }

    if (!this.filter || !this.filter.filters) {
      // ако няма никакви филтри създава основа
      this.filter = {
        logic: 'and',
        filters: [
          {
            logic: 'or',
            filters: filters,
          },
        ],
      };
    } else {
      // ако има филтри тъси по field правилното място, в което да ги пълни
      let matchSomeWare = false;
      this.filter.filters.forEach((level1: any) => {
        if (level1.filters) {
          let match = false;
          level1.filters.forEach((level2: any) => {
            if (level2.field === this.field) {
              match = true;
              matchSomeWare = true;
            }
          });
          if (match) {
            level1.filters = filters;
          }
        }
      });

      // ако не намери къде да ги пълни, прави нов масив и ги слага там
      // това обикновенно се случа първия път
      // (когато има заредени някакви други филтри(dataset-a не е null) и се цъкне този за мултипъл types)
      if (!matchSomeWare) {
        this.filter.filters.push({
          logic: 'or',
          filters: filters,
          value: undefined,
        });
      }
    }

    // clean empty filters
    if (this.filter && this.filter.filters) {
      const indexesToClean: Array<number> = [];
      this.filter.filters.forEach((filter: any) => {
        if (filter.filters && filter.filters.length === 0) {
          indexesToClean.push(this.filter.filters.indexOf(filter));
        }
      });
      for (let i = indexesToClean.length - 1; i >= 0; i--) {
        this.filter.filters.splice(indexesToClean[i], 1);
      }
    }
 
    this.filterChanged.next(this.filter);
  }

  loadTypes() {
    log.debug('loadTypes');
    this.apiQuery.setSort('order_index', ApiQuerySortTypes.ASC);

    this.typeService
      .index(
        this.apiQuery
          .addFilters({
            type: this.groupType,
          })
          .setLimit(9999)
      )
      .subscribe((response: PaginatedResponse) => {
        this.data = response.data;
        this.loader = false;
      });
  }

  isTypeSelected(typeId: number): boolean {
    return this.form.get('value').value && this.form.get('value').value.indexOf(typeId) >= 0;
  }
}
